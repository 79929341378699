import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Typography from "@material-ui/core/Typography";
import Title from "../components/Title";
import Card from "../components/Card";
import Seo from "../components/Seo";

function Services({
  data: {
    allContentfulServices: { nodes: services },
  },
}) {
  return (
    <>
      <Seo title="Services" />
      <Layout>
        <section className="services-page">
          <section className="section">
            <Typography align="center" component="h1" variant="h2">
              Services
            </Typography>
            <div className="section-center services-center">
              {services.map((service) => {
                let image;
                if (
                  service.image &&
                  service.image.file.contentType === "image/gif"
                ) {
                  image = service.image.fluid;
                } else if (
                  service.image &&
                  service.image.file.contentType === "image/svg+xml"
                ) {
                  image = service.image.svg.dataURI;
                } else {
                  image = undefined;
                }

                return (
                  <Card
                    slug={service.slug}
                    key={service.id}
                    title={service.title}
                    smallDescription={service.subTitle}
                    image={image}
                    imageFormat={service.image.file.contentType}
                    backgroundColor={
                      service.color.primary ? service.color.primary : "#333"
                    }
                    cta="En savoir plus"
                  />
                );
              })}
            </div>
          </section>
        </section>
      </Layout>
    </>
  );
}

export default Services;

export const query = graphql`
  {
    allContentfulServices {
      nodes {
        title
        id
        subTitle
        color {
          primary
        }

        slug
        image {
          file {
            contentType
          }
          fluid {
            ...GatsbyContentfulFluid
          }
          svg {
            dataURI
          }
        }
        targeting {
          title
          description {
            description
          }
        }
      }
    }
  }
`;
