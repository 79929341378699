import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { FaRegCalendarCheck, FaDollarSign } from "react-icons/fa";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "grid",
    marginBottom: "2rem",
    background: "white",
    bordeRadius: "10px",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s linear",
    "&:hover": {
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.4)",
      transform: "scale(1.02)",
    },
  },
}));

function Card({
  slug,
  id,
  title,
  subTitle,
  image,
  price,
  date,
  backgroundColor,
  imageFormat,
  description,
  smallDescription,
  cta,
}) {
  const classes = useStyle();
  const theme = useTheme();
  return (
    <Link to={`/${slug}/`} className={classes.root} key={id}>
      <article className="article">
        {image ? (
          imageFormat === "image/svg+xml" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                justifyItems: "center",
                backgroundColor: "#dddddd",
              }}
            >
              {" "}
              <img src={image} alt={title}></img>
            </div>
          ) : (
            <div>
              <Image
                className="card-img"
                // style={{ backgroundSize: "contain", paddingTop: "200px" }}
                fixed={image}
              />
            </div>
          )
        ) : (
          <div
            className="card-img"
            style={{
              backgroundColor: `${backgroundColor}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              component="h3"
              variant="h5"
              align="center"
              style={{
                padding: "20px",
                color: "white",
                textTransform: "uppercase",
              }}
            >
              {title}
            </Typography>
          </div>
        )}

        {date || price ? (
          <div className="card-footer">
            {price ? (
              <div
                style={{
                  display: "flex",

                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "0.5rem",
                }}
              >
                <FaDollarSign
                  color="#34495E"
                  size={30}
                  style={{ marginRight: "5px" }}
                />
                <Typography style={{ color: "#34495E" }}>
                  {price} DA{" "}
                </Typography>
              </div>
            ) : null}
            {date ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "0.5rem",
                }}
              >
                <FaRegCalendarCheck
                  color="#34495E"
                  size={30}
                  style={{ marginRight: "5px" }}
                />
                <Typography style={{ color: "#34495E" }}>
                  {date.includes("2020") ? date.split("2020")[0] : date}{" "}
                </Typography>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="card-head">
          <Typography component="h2" variant="h6">
            {title}
          </Typography>
          <Typography component="p" variant="body2">
            {smallDescription}
          </Typography>
        </div>
      </article>
    </Link>
  );
}

export default Card;
