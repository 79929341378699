import React from "react";
import Typography from "@material-ui/core/Typography";

function Title({ title }) {
  return (
    <div className="section-title">
      <Typography component="h2" variant="h4">
        {title || "titre par default"}
      </Typography>
    </div>
  );
}

export default Title;
